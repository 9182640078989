/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import Facebook from "react-feather/dist/icons/facebook"

import styled from "styled-components"

import "../lang/i18n"

import React from "react"
import { LanguageChanger } from "../lang/LanguageChanger"
import "./layout.css"
import "./main.css"

const Layout = ({ children }) => {
  return (
    <Wrapper>
      <LanguageChanger />
      <main>{children}</main>
      <footer>
        <span>© {new Date().getFullYear()} Little Lambs School</span>
        <span className="right">
          <a
            href="https://www.facebook.com/littlelambsschool"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Facebook />
          </a>
        </span>{" "}
      </footer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0;
  main {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 106px);
  }
  footer {
    position: relative;
    background: #111111;
    padding: 40px;
    color: white;
    text-align: center;
    .right {
      text-align: right;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 20px;
      a {
        color: white;
      }
    }
  }
`

export const Container = styled.div`
  background: white;
  color: #111;
  padding: 20px;
  margin: 20px auto;
  @media screen and (max-width: 1000px) {
    margin: 20px 10px;
  }
  width: calc(100% - 20px);
  max-width: 1000px;
`

export default Layout
