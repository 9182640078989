import React from "react"

import styled from "styled-components"
import logo from "../../images/logo_white.svg"
import { Trans } from "react-i18next"
export function Heading() {
  return <HeaderBackground />
}
export function LogoHeading() {
  return (
    <StyledLogoHeading>
      <GatsbyLink to="/">
        <img src={logo} alt="logo" />
      </GatsbyLink>
    </StyledLogoHeading>
  )
}
const StyledLogoHeading = styled.div`
  width: 100%;
  img {
    display: block;
    margin: 0 auto;
  }
`

let ticking = false
let lastScrollY = 0
export class HeaderBackground extends React.Component {
  state = {
    opacity: "1",
    transform: "none",
  }
  componentDidMount() {
    window.addEventListener("scroll", this.scroll)
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scroll)
  }
  scroll = e => {
    lastScrollY = window.scrollY
    if (!ticking) {
      ticking = true
      window.requestAnimationFrame(() => {
        //animate.
        let opacity = map(lastScrollY, 0, 200, 1, 0.5, true).toFixed(2)
        let transform = map(lastScrollY, 0, 200, 1, 0.8, true).toFixed(2)
        this.setState({
          opacity,
          transform,
        })
        ticking = false
      })
    }
  }
  render() {
    const { opacity, transform } = this.state
    return (
      <HeaderSpace>
        <HeadingText
          style={{
            opacity: Number(opacity),
            transform: `scale(${transform})`,
          }}
        >
          <img src={logo} alt="logo" />
          <h1>
            <span className="small">
              <Trans i18nKey="welcome" />
            </span>
            <br />
            Little Lambs School
          </h1>
          <br />
          <SquareButton href="#main" offset="50px">
            <b>
              <Trans i18nKey="readMore" />
            </b>
          </SquareButton>
        </HeadingText>
      </HeaderSpace>
    )
  }
}
import AnchorLink from "../anchor-link/AnchorLink"
import GatsbyLink from "gatsby-link"

const SquareButton = styled(AnchorLink)`
  padding: 18px;
  border: 4px solid white;
  color: white;
  max-width: 300px;
  margin: 0 auto;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  @media screen and (max-width: 600px) {
    border: 2px solid white;
    font-size: 16px;
  }
  /* position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, 0); */
`

const constrain = function(n, low, high) {
  return Math.max(Math.min(n, high), low)
}

const map = function(n, start1, stop1, start2, stop2, withinBounds) {
  var newval = ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2
  if (!withinBounds) {
    return newval
  }
  if (start2 < stop2) {
    return constrain(newval, start2, stop2)
  } else {
    return constrain(newval, stop2, start2)
  }
}

export const RelativeFlexDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeaderSpace = styled.div`
  height: calc(100vw - 400px);
  max-height: 600px;
  position: relative;
  @media screen and (max-width: 900px) {
    height: 370px;
  }
  img {
    margin: 0;
    height: 100px;
  }
`
const HeadingText = styled.div`
  position: absolute;
  top: calc(50% - 170px);

  /* left: 50%; */
  text-align: center;
  letter-spacing: 4px;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;

  transition-property: transform, opacity;
  transition-duration: 50ms;
  transition-timing-function: ease-in-out;

  h1 {
    font-size: 48px;
    margin: 10px;
  }
  span.small {
    font-family: "Crimson Text", serif;

    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 32px;
  }
  img {
    height: 120px;
  }
  @media screen and (max-width: 900px) {
    /* top: 10%; */
    top: calc(50% - 109px);
    h1 {
      font-size: 42px;
    }
    span.small {
      font-size: 20px;
    }
    img {
      height: 80px;
    }
    a {
      display: none;
    }
  }
`
