import { useTranslation } from "react-i18next"
import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"

const StyledLanguageChanger = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  padding: 20px;
  font-size: 22px;
  z-index: 1000;

  animation: fadeIn 0.5s linear 0.5s 1 forwards;

  background: #222;
  span {
    cursor: pointer;
    text-decoration: underline;
  }
  color: white;
`

export function LanguageChanger() {
  const { t, i18n } = useTranslation()
  const [show, setShow] = useState(true)
  const timerRef = useRef(null)
  const restartTimer = () => {
    clearTimeout(timerRef.current)
    const timer = setTimeout(() => {
      setShow(false)
    }, 1000 * 10)
    timerRef.current = timer
  }
  useEffect(() => {
    //if user has closed this modal more than 3 times dont show again.
    const closedAmount =
      parseInt(window.localStorage.getItem("closedLanguage")) || 0
    if (window.sessionStorage.getItem("closedLanguage") || closedAmount > 3) {
      setShow(false)
    }
    const timer = setTimeout(() => {
      setShow(false)
    }, 1000 * 10)
    timerRef.current = timer
    return () => clearTimeout(timer)
  }, [])
  if (!show) return null
  console.log("current language", i18n.language)
  const next = i18n.language.includes("en") ? "Svenska" : "English"
  const nextCode = i18n.language.includes("en") ? "sv" : "en"

  return (
    <StyledLanguageChanger
      onMouseEnter={() => {
        clearTimeout(timerRef.current)
      }}
      onMouseLeave={restartTimer}
    >
      {t("Looking for the site in") + " "}
      <span
        onClick={() => {
          i18n.changeLanguage(nextCode)
          restartTimer()

          // setShow(false)
        }}
      >
        {next + "?"}
      </span>
      <div
        className="rodal-close"
        onClick={() => {
          setShow(false)
          window.sessionStorage.setItem("closedLanguage", "1")
          const count =
            parseInt(window.localStorage.getItem("closedLanguage")) || 0
          window.localStorage.setItem("closedLanguage", String(count + 1))
        }}
      />
    </StyledLanguageChanger>
  )
}
