/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

type Meta = { name?: string; property?: string; content: string }
function SEO({
  description,
  meta = [],
  title,
}: {
  description?: string
  meta?: Meta[]
  title?: string
}) {
  const { site, background } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
        background: file(relativePath: { eq: "background@2x.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              src
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const metaArray: Meta[] = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:url`,
      content: "https://www.littlelambsschool.org/",
    },
    {
      property: `og:title`,
      content: "Little Lambs School",
    },
    {
      property: `og:image`,
      content: background.childImageSharp.fluid.src,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `fb:app_id`,
      content: `459166344632437`,
    },
    {
      name: `twitter:card`,
      content: metaDescription,
    },
  ]

  meta.forEach(newMeta => {
    const existingIndex = metaArray.findIndex(
      m => m.property === newMeta.property
    )
    if (existingIndex !== -1) {
      metaArray[existingIndex].content = newMeta.content
    } else {
      metaArray.push(newMeta)
    }
  })

  return (
    <Helmet
      // htmlAttributes={{
      //   lang,
      // }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={metaArray}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
