import { graphql, useStaticQuery } from "gatsby"
import Image, { FixedObject, FluidObject } from "gatsby-image"
import React, { useLayoutEffect, useRef } from "react"
import styled from "styled-components"
import schoolImg from "../images/school_bg@2x.jpg"

export function BackgroundImage({ image = "background" }) {
  const images = useImages()
  return (
    <StyledBackgroundImage
      alt="Little Lambs School"
      style={{
        position: "fixed",
        zIndex: -1,
        width: "100%",
        minHeight: "400px",
      }}
      // @ts-ignore
      fluid={images[image].childImageSharp.fluid}
    />
  )
}
export const StyledBackgroundImage = styled(Image)`
  position: fixed;
  z-index: -1;
  object-fit: cover;
  width: 100%;
  min-height: 400px;
  /* 4k screens */
  @media screen and (min-width: 2200px) {
    top: -240px;
  }
`
export const SchoolBackground = () => {
  const imageDiv = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    imageDiv.current.style.backgroundImage = `url(${schoolImg})`
  }, [])
  return <StyledSchoolBackground ref={imageDiv} />
}
const StyledSchoolBackground = styled.div`
  /* background-image: ; */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 40% 50%;

  height: 550px;
  @supports (-webkit-overflow-scrolling: touch) {
    background-attachment: scroll;
  }
  @media screen and (max-width: 900px) {
    background-attachment: scroll;
  }
`

export function useImages(): {
  background: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  hanging: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  helpUs: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  volonteer: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  maria: {
    childImageSharp: {
      fluid: FluidObject
      fixed: FixedObject
    }
  }
} {
  return useStaticQuery(graphql`
    query FrontPageImages {
      background: file(relativePath: { eq: "background@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      hanging: file(relativePath: { eq: "hanging@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 757) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      helpUs: file(relativePath: { eq: "help_us@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      volonteer: file(relativePath: { eq: "volunteer@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      maria: file(relativePath: { eq: "maria@2x.jpg" }) {
        childImageSharp {
          fixed(width: 325, height: 325) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)
}
export const ChildrenHangingImage = () => {
  const { hanging } = useImages()
  return (
    <Image
      fluid={hanging.childImageSharp.fluid}
      className="hide_on_small"
      alt="Children playing"
    />
  )
}
export const HelpUsImage = () => {
  const { helpUs } = useImages()
  return (
    <Image
      alt="Join hands with us"
      fluid={helpUs.childImageSharp.fluid}
      style={{ width: "100%", height: "100%" }}
    />
  )
}
export const VolonteerImage = () => {
  const { volonteer } = useImages()
  return (
    <Image
      alt="Volunteer with us"
      fluid={volonteer.childImageSharp.fluid}
      style={{ width: "100%", height: "100%" }}
    />
  )
}
export const MariaImage = () => {
  const { maria } = useImages()
  return (
    <Image
      alt="Maria"
      fixed={maria.childImageSharp.fixed}
      style={{ maxWidth: "100%" }}
      imgStyle={{ width: "325px", height: "325px" }}
    />
  )
}
