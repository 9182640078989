import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          "Please fill in your name and email":
            "Please fill in your name and email.",
          captch_error: "Please fill in the captcha correctly!",
          "Looking for the site in": "Letar du efter sidan på",
          Hem: "Home",
          "Hjälp till": "Help us",
          Volontärer: "Volunteers",
          Kontakt: "Contact",
          "Tack för ditt meddelande!": "Thank you for you message!",
          "Tillbaka!": "Back!",
          "404-message": "You just hit a route that doesn't exist!",
          "Tillsammans förändrar vi världen – ett barn i taget":
            "Let’s change the world – one child at a time",
          "Om oss": "About us",
          welcome: "Welcome to",
          readMore: "Read more",

          Barnen: "The children",
          Barnhem: "Boarding homes",
          Skolan: "The school",
          Mat: "Nutrition",
          Sjukvård: "Health care ",
          ÄldreElever: "Older students",
          "Socialt arbete": "Social work",
          Läger: "Camps",
          Barnen_text:
            "The aim of the trust is to offer excellent education to children from under privileged background. Some of the older children have never been to school before and some have learning difficulties and have failed in other schools.",
          Barnhem_text:
            "Children who come from insecure and unstable backgrounds stay in our homes. Some have been living on the street and have been begging, some have been abused and some are neglected due to the parent's substance abuse. ",
          Skolan_text:
            "The school has around 180 students from 3-12 years. We have four kindergarten classes and primary school up to grade 6. The student–teacher ratio is low which enables the children to get extra support from engaging teachers. There is a lovely playground, computer lab and library at the premises.",
          Mat_text:
            "All children get a glass of milk enriched with a nutritious supplement for breakfast. For the most needy children, lunch is given as well. Twice a week, fruit is given.",
          Sjukvård_text:
            "We check the children’s growth twice a year and make sure that extra food is given when needed. We also check their eye sight and buy glasses, give free dental treatment and occasionally pay for hospital costs.",
          ÄldreElever_text:
            "When the students have finished at Little Lambs School, we stay in touch with them and continue to support them throughout their schooling. We also help to pay for vocational training and university fees. ",
          SocialtArbete_text: `Our social worker and psychologist pick up children from the street who need schooling. In some cases we place them in hostels or foster homes. We also try to rehabilitate the parents through counselling and training. At times we give extra food supplies to the families who are in need.`,
          Läger_text:
            "We take our students, youth and families for camps to get away from the city and get a chance to enjoy themselves in a healing environment. ",
          mariaText1:
            "No one can change the whole world, but everyone can change the world for one child by giving them hope for the future. I believe that we together can change the world, one child at a time by giving them access to education.",
          mariaText2: `25 years ago, me and my husband came to India, longing to make a difference. India is intense, colourful and chaotic with its crazy traffic, pollution, cows on the streets and stifling heat, but the people are wonderful! In the midst of this chaos, our journey started to support needy children and give them hope for the future. I am so happy that we are able to break the cycle of poverty and make a difference for not only individual children but for their families as well. Together with our fantastic teachers, social worker and sponsors from all over the world, I know I make a difference in supporting these children and giving them a bright future.
            <br />
            <br />
          <i>Maria Gislén, founder and principal</i>
            `,
          "Jobba som volontär": "Volunteer with us",
          volonteerText: `Volunteers are very important for our work. They bring an extra dimension to the work and make life exciting for the children. 
          As a volunteer, you will be living like one of the locals and experience Indian life from within. You will get to know some of the children's families and get a good understanding of Indian culture. Apart from assisting the teachers, volunteers can help out in the boarding homes and people experienced in social work or administration can also make a valuable contribution to the work. It is important that you are flexible and curious to get to know a different culture to make the most of your stay.`,

          volonteer1: `<i>How long do volunteers usually stay?</i>
          <br />
          Most volunteers stay 2-5 months, but even shorter periods can be considered. `,
          volonteer2: `<i>How much does it cost to be a volunteer?</i>
          <br />
          It doesn’t cost anything to be a volunteer, but you have to cover your own expenses. We can help in arranging accommodation. The cost of living in India is very low and you can live on almost nothing if you want to.
          `,
          volonteer3: `<i>How do I get from the airport? </i>
          <br />
          Someone will pick you up and take you to the school or the place where you will be staying.
          `,
          volonteer4: `<i>What do I do as a volunteer? </i>
          <br />
          We are very flexible, but most volunteers help out with assisting the teachers by reading with the children, give extra support in Maths, do art, sports, music, drama etc, depending on your interests and abilities. 
          `,
          volonteer5: `<i>Can I do my internship at the school? </i>
          <br />
          Yes, that is possible. We have had people studying to become teachers or social workers who have done their internship with us. 
          `,

          helpUsHeading: "Join hands with us!",
          helpUsText: `Our school does not have any government funding, but is run with the help of donations. All money given, goes straight into the work with the children. You can give a one-off donation or sponsor a child for Rs 1200 per month ($15, €14, £12). 
          <br />
          It doesn’t cost much to make a real difference!`,
          helpUsButton: `Donate`,
          donate_header: `Donate`,
          donate_text: `Contact us using the form below if you wish to sponsor a child.`,
          donate_details: `
          <h4><i>Payments from India:</i></h4>
Little lambs educational trust, A/c number 435391578 Indian bank, IFSC code IDIB000P034, Perambur branch.
<br /><br />
Cheques can be made payable in favour of Little Lambs Educational Trust and be sent to H-2022, 12th Main Road, 5th Street, Anna Nagar West, Chennai 600 040, India. The trust is exempted from tax under 80G.
<br />
<br />
<h4><i>Payments from Sweden:</i></h4>
Bankgiro number: 5064-5985.
Swish number: 123 390 63 77
<br /><br />
<h4><i>International payments:</i></h4>
Bank: SWEDBANK, Hoppets Torg, SE-553 21 Jönköping, Sweden<br />
IBAN: SE72 8000 0815 0594 4782 9996<br />
BIC: SWEDSESS<br />
Account Holder:<br />
LITTLE LAMBS FRIENDS<br />
C/o Lars Wallström<br />
Sveavägen 51, Lgh 1402<br />
SE-554 47 Jönköping<br />
Organisation number: 802498-5346
          `,
          contactUs: "Contact us",
          contactUsText:
            "The school is located at the outskirts of Chennai, one of the largest cities in India.",
          address: "Address",
          "Ditt namn": "Your name",
          "Din epost": "Your email",
          "Ditt meddelande": "Your message",
          Send: "Send",
          follow_us_on_facebook: "Follow us on Facebook",
          newsText: `
          Follow us on our Facebook page to get recent updates.
          `,
          News: "News",
          "Latest news": "Latest news",
        },
      },
      sv: {
        translations: {
          "Please fill in your name and email":
            "Vänligen fyll i ditt namn och din epostadress.",
          captch_error: "Vänligen fyll i captchan!",
          "Looking for the site in": "Looking for the site in",
          Hem: "Hem",
          "Hjälp till": "Hjälp till",
          Volontärer: "Volontärer",
          Kontakt: "Kontakt",
          "Tack för ditt meddelande!": "Tack för ditt meddelande!",
          "Tillbaka!": "Tillbaka!",
          "404-message": "Hittade ingen sådan sida!",
          "Tillsammans förändrar vi världen – ett barn i taget":
            "Tillsammans förändrar vi världen – ett barn i taget",
          "Om oss": "Om oss",
          welcome: "Välkommen till",
          readMore: "Läs mer",

          Barnen: "Barnen",
          Barnhem: "Barnhem",
          Skolan: "Skolan",
          Mat: "Mat",
          Sjukvård: "Sjukvård",
          ÄldreElever: "Äldre elever",
          "Socialt arbete": "Socialt arbete",
          Läger: "Läger",
          Barnen_text: `Målet med arbetet är att erbjuda god utbildning till barn som inte har råd att gå i skolan. Familjerna är mycket fattiga. Vissa av de äldre barnen har aldrig gått i skolan tidigare. Några elever har inlärningssvårigheter och har misslyckats i andra skolor. `,
          Barnhem_text: `Barn som är extra utsatta får plats på våra två barnhem. De har tidigare bott på gatan, varit tiggare, kommer från missbrukarhem eller blivit utsatta för övergrepp.`,
          Skolan_text: `Skolan har ca 180 elever från 3-12 år. Vi har fyra förskoleklasser och grundskola till och med årskurs 6 med individanpassad undervisning, vilket gör att barn med inlärningssvårigheter kan få extra stöd och hjälp. En härlig skolgård, datarum, bibliotek och engagerade lärare hjälper till att skapa en god inlärningsmiljö för eleverna.`,
          Mat_text: `Till frukost får barnen mjölk berikad med olika näringsämnen. Skolan erbjuder lunch till de elever som behöver det. Två i veckan får barnen även frukt.`,
          Sjukvård_text: `Vi utför hälsoundersökningar på alla barnen. Vi följer barnens tillväxtkurvor, gör synundersökningar, hjälper till med sjukhuskostnader och tandvård. Vi ser till att barnen som är undernärda får extra mat. `,
          ÄldreElever_text: `När eleverna slutat på Little Lambs School fortsätter vi att hålla kontakten med dem genom regelbundna samlingar och läger. Vi ger ekonomiskt stöd så de kan gå färdigt gymnasiet och därefter få en yrkesutbildning eller läsa på högskolan.`,

          SocialtArbete_text: `Vår socialarbetare och kurator plockar upp barnen från gatan som behöver skolgång och vid behov placerar dem på barnhem eller i fosterhem. Vi försöker också rehabilitera föräldrarna genom samtal, träning och utbildning. Vi delar också ut matkassar vid behov.`,
          Läger_text: `Vi ordnar läger får våra elever, ungdomar och familjer för att ge dem möjlighet att komma bort från stan och få glädje och gemenskap i en helande miljö.`,
          mariaText1: `Att förändra hela världen kan förstås ingen människa göra. Men varje
          människa kan förändra världen för ett barn genom att ge dem hopp och
          möjlighet till en god framtid. Jag tror och vet att det går att
          förändra världen, ett barn i taget. Och nyckeln till detta är
          utbildning.`,
          mariaText2: `För 25 år sedan landade jag och min man Mikael i Indien med en längtan
          att få göra skillnad. Indien var intensivt, färgstarkt, omtumlande och
          ibland krävande med sin hetsiga trafik, avgaser, kor på gatorna och
          den tryckande värmen. Men människorna, människorna var då som nu, helt
          fantastiska!

          Mitt i allt detta började vår resa för att ge fler barn
          förutsättningar för en god och trygg framtid. Idag kan jag glädjande
          konstatera att vi genom vår verksamhet har Little Lambs School kunnat hjälpa
          många underprivilegierade barn att klara sig genom sin skolgång och på
          sikt kunna förändra livsvillkoren för hela sin familj. Genom att följa
          våra barn även efter att de slutat hos oss och ge dem ekonomiskt stöd
          att fortsätta sin utbildning bryter vi fattigdomscykeln och skapar ett
          bättre liv för hela familjer. Tillsammans med våra fantastiska lärare,
          socialarbetare och faddrar från hela världen känner jag att jag gör
          skillnad varje dag genom att stötta och uppmuntra våra elever att
          studera för att skapa sig en ljus framtid.
          <br />
          <br />
          <i>Maria Gislén, grundare och rektor</i>
          `,
          "Jobba som volontär": "Jobba som volontär",
          volonteerText: `Volontärer är mycket viktiga för arbetet i skolan. De tillför en
          extra dimension i skolans vardag och gör livet spännande för
          barnen. Som volontär kommer du att leva som lokalbefolkningen
          och uppleva det verkliga indiska livet. Du kommer att lära känna
          skolbarnens och personalens familjer och få en bra förståelse
          för kulturen.

          Vi välkomnar också volontärer till barnhemmet och de som har
          erfarenhet av socialt arbete eller administration. Det är
          viktigt att man är flexibel och nyfiken på en ny kultur för att
          få ut det mesta av sin vistelse.`,

          volonteer1: `<i>Hur länge brukar volontärer stanna?</i>
          <br />
          De flesta av våra volontärer stannar 2-5 månader, men även kortare
          vistelser kan förekomma.`,
          volonteer2: `<i>Hur mycket kostar det att vara en volontär?</i>
          <br />
          Det kostar ingenting att vara volontär, men du står för dina egna
          utgifter. Vi kan hjälpa till att ordna boende. Levnadskostnaderna i
          Indien är generellt mycket låga och du kan leva på nästan ingenting
          om du vill.`,
          volonteer3: `<i>Hur kommer jag från flygplatsen?</i>
          <br />
          Någon hämtar dig och tar dig till skolan eller lägenheten där du
          bor.`,
          volonteer4: `<i>Vad hjälper jag till med som volontär?</i>
          <br />
          Vi är väldigt flexibla! Det beror på vad du vill bidra med.
          Vanligtvis arbetar volontärerna med att hjälpa barnen att läsa,
          grundläggande matematik, bild, sport, musik, med mera.`,
          volonteer5: `<i>Kan jag göra min praktik hos er?</i>
          <br />
          Ja, det går bra. Vi har tidigare tagit emot praktikanter från
          förskollärar-, lärar- och socionomprogrammet.`,

          helpUsHeading: "Hjälp oss att hjälpa fler!",
          helpUsText: `Vår skola får inget statligt stöd utan arbetet drivs helt med
          hjälp av gåvor och varje krona går oavkortat till barnen
          <strong> utan mellanhänder.</strong>
          <br />
          Du kan skänka ett engångsbelopp eller välja att bli
          månadsfadder genom att stötta ett specifikt barns skolgång för
          150 kronor i månaden.
          <br />
          Din gåva gör skillnad!`,
          helpUsButton: `Skänk en gåva`,
          donate_header: `Skänk en gåva`,
          donate_text: `Du kan välja att ge en engångsgåva via Swish eller Bankgiro.
          Kontakta oss med formuläret nedan om du vill bli månadsfadder.`,
          donate_details: `
          Swish: <b> 123 3906377</b>
              <br />
              Bg: <b>5064-5985</b>
              <br />
          `,
          contactUs: "Kontakta oss",
          contactUsText:
            "Skolan ligger i utkanten av Chennai som är en av Indiens största städer.",
          address: "Adress",
          "Ditt namn": "Ditt namn",
          "Din epost": "Din epost",
          "Ditt meddelande": "Ditt meddelande",
          Send: "Skicka",
          newsText: `
          Följ oss på vår Facebooksida för att se vad som händer på skolan.
          <br />
          Du kan ladda ner vår broschyr och sprida den till fler.
          `,
          follow_us_on_facebook: "Följ oss på Facebook",
          News: "Nyheter",
          "Latest news": "Senaste nytt",
          "Ladda ner broschyr": "Ladda ner broschyr",
        },
      },
    },
    fallbackLng: "en",
    debug: process.env.NODE_ENV === "development",

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
